import String from '../components/String'
import FretNumbers from "./FretNumbers";

export default function FrzChord (props) {
  const {quality, selected, strPitches, ext} = props.chord,
    {numOfFrets, allNotes} = props,
    pitch = selected[0]

  return (
    <div className="frz-chord">
      <h4>{pitch + quality + (ext !== 'none' ? ' ' + ext : '')}</h4>
      <div className="neck">
        <div className={`all-strings has-${strPitches.length}-strs`}>
          {strPitches.map((p, i) => <String 
            allNotes={allNotes} pitch={p} selected={selected} frz={true} numOfFrets={numOfFrets} key={i}
            />)
          }
        </div>
        <FretNumbers numOfFrets={numOfFrets} />
      </div>
    </div>
  )
} 
