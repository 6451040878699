export default function FretNumbers ({numOfFrets}) {
  const fretStart = numOfFrets[0], fretEnd = numOfFrets[1]
  // const numOfFrets = Array(props.numOfFrets).fill('')
  const frets = Array(fretEnd - fretStart).fill('')
  return (
    <div className="fret-nums">
      <span className="open fret">Open</span>
      {frets.map((e, i) => (
        <div className="fret" key={i}>
          <span>{i + 1}</span>
        </div>
      ))}
    </div>
  )
}
