import React from 'react'
import Fret from './Fret'

export default class String extends React.Component {
  constructor() {
    super()
    this.state = {
      pitch: '',
      notes: ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'],
      extIdxs: ['root', 'third', 'fifth', 'sixth', 'seventh', 'ninth', 'eleventh']
    }
  }
  componentDidMount() {
    const {pitch} = this.props
    if (pitch) {
      this.setNotes(pitch)
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const {pitch, allNotes} = this.props,
      {notes} = this.state,
      oldPitch = prevProps.pitch,
      oldAllNotes = prevProps.allNotes,
      oldNotes = prevState.notes
    if (
      pitch !== oldPitch ||
      !notes.every((n, i)=> n === oldNotes[i]) ||
      !allNotes.every((n, i)=> n === oldAllNotes[i])
    )  this.setNotes(pitch)
  }
  setNotes(pitch) {
    this.setState({pitch})
    const {frz, numOfFrets, allNotes} = this.props
    let idx = allNotes.indexOf(pitch)
    let newNotes = [], fretStart, fretEnd
    frz ? 
      [fretStart, fretEnd] = numOfFrets : 
      [fretStart, fretEnd] = [0, 11]
    for (let i = fretStart; i < fretEnd + 1; i++, idx++) {
      newNotes[i] = allNotes[idx + (frz ? 0 :1)]
      if (idx === 11)  idx = -1
    }
    this.setState({notes: newNotes})
  }
  findRel(noteName) {
    const {extIdxs} = this.state, {selected} = this.props
    if (selected.includes(noteName)) {
      return extIdxs[selected.indexOf(noteName)]
    }
  }
  render() {
    const {allNotes, name, pitch, setStrPitch, frz} = this.props,
      {notes} = this.state,
      openRel = this.findRel(pitch)
    return (
      <div className="string">
        {frz ? '' :
          <div className="fret str-select">
            <select name={name} value={pitch} onChange={setStrPitch} className={openRel ? `selected ${openRel}` : ''}>
              {allNotes.map((n, i) => <option key={n + i} >{n}</option>)}
            </select>
          </div>
        }
        {notes.map((note, idx) => {
          return <Fret pitch={note} key={note + idx}
            relation={this.findRel(note)}/>
        })}
      </div>
    )
  }
}
