export default function Options (props) {
  const {sharpsOrFlats, setSharpsOrFlats, numStrings, setNumStrings, allStringsStep, frozen} = props
  return (
    <div className="options">
      <div className="row">
        <form className="sharps-or-flats">
          <label>
            <input disabled={frozen} type="radio" name="sharps" value="sharps" checked={sharpsOrFlats === 'sharps'} onChange={setSharpsOrFlats} />
            <span>Sharps</span>
          </label>
          <label>
            <input disabled={frozen} type="radio" name="flats" value="flats" checked={sharpsOrFlats === 'flats'} onChange={setSharpsOrFlats} />
            <span>Flats</span>
          </label>
        </form>
        <div className="num-of-strings">
          <span>Number of Strings:</span>
          {/* <input type="number" name="numStrings" value={numStrings} onChange={(e) => setNumStrings(e.target.value)} min="0" max="12" /> */}
          <div className="str-options">
            <button className="strings-4" type="button" onClick={() => setNumStrings(4)}>4</button>
            <button className="strings-6" type="button" onClick={() => setNumStrings(6)}>6</button>
            <button className="strings-12" type="button" onClick={() => setNumStrings(12)}>12</button>
          </div>
          <div className="increment">
            <button className="strings-minus" type="button" onClick={() => setNumStrings(numStrings - 1)}>-</button>
            <button className="strings-plus" type="button" onClick={() => setNumStrings(numStrings + 1)}>+</button>
          </div>
        </div>
        <div className="all-strs-step">
          <span>Step All Strings</span>
          <button className="all-flat" type="button" onClick={() => allStringsStep('flat')}>&#9837;</button>
          <button className="all-sharp" type="button" onClick={() => allStringsStep('sharp')}>#</button>
        </div>
      </div>
    </div>
  )
}
