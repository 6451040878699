const qualities = ['maj', 'min', 'dom'],
  exts = ['none', '6', '7', '9', '6/9', 'add 9', '11', 'add 11', 'all']
export default function ChordSel (props) {
  const {allNotes, chordTonic, quality, ext, handleChange, freezeChord, clearFrzChords, } = props
  return (
    <div className="chord-sel">
      <div className="chord">
        <label>Select a Chord: </label>
        <select name="chordTonic" value={chordTonic} onChange={handleChange} className="chordTonic">
          {allNotes.map((n, i) => <option key={n + i} >{n}</option>)}
        </select>
        <select name="quality" value={quality} onChange={handleChange} className="quality">
          {qualities.map((q) => <option key={q} >{q}</option>)}
        </select>
        <select name="ext" value={ext} onChange={handleChange} className="ext">
          {exts.map((e) => <option key={e} >{e}</option>)}
        </select>
      </div>
      <div className="freeze-ctrls">
        <button className="frz-btn" type="button" onClick={freezeChord}>Freeze Chord</button>
        <button className="clear-btn" type="button" onClick={clearFrzChords}>Clear Frozen Chords</button>
      </div>
    </div>
  )
}
