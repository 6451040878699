import React from 'react';
import './App.scss';
import String from './components/String'
import ChordSel from './components/ChordSel'
import FrzChord from './components/FrzChord'
import FretNumbers from './components/FretNumbers';
import Options from './components/Options';
class App extends React.Component {
  constructor() {
    super()
    this.state = {
      chordTonic: 'D',
      quality: 'maj',
      ext: 'none',
      allNotes: ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B', 'C'],
      strPitches: ['E', 'B', 'G', 'D', 'A', 'E'],
      selected: [],
      frzChords: [],
      sharpsOrFlats: 'sharps'
    }
  }
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    })
    this.getSelected()
  }
  getSelected() {
    const {allNotes, chordTonic, quality, ext} = this.state
    const idx = allNotes.findIndex(p => p === chordTonic)
    const chordTones = {
      first: chordTonic,
      third: quality === 'min' ? 
        allNotes[idx + 3] || allNotes[idx - 9] :
        allNotes[idx + 4] || allNotes[idx - 8],
      fifth: allNotes[idx + 7] || allNotes[idx - 5],
      sixth: quality === 'min' ?
        allNotes[idx + 8] || allNotes[idx - 4] :
        allNotes[idx + 9] || allNotes[idx - 3],
      seventh: quality === 'maj' ?
        allNotes[idx + 11] || allNotes[idx - 1] :
        allNotes[idx + 10] || allNotes[idx - 2],
      ninth: allNotes[idx + 2] || allNotes[idx - 10],
      eleventh: allNotes[idx + 5] || allNotes[idx - 7]
    }
    const {first, third, fifth, sixth, seventh, ninth, eleventh} = chordTones
    if (ext === '6') {
      this.setState({selected: [first, third, fifth, sixth]}) 
    } else if (ext === '6/9') {
      this.setState({selected: [first, third, fifth, sixth, '', ninth]})
    } else if (ext === '7') {
      this.setState({selected: [first, third, fifth, '', seventh]}) 
    } else if (ext === '9') {
      this.setState({selected: [first, third, fifth, '',  seventh, ninth]})
    } else if (ext === 'add 9') {
      this.setState({selected: [first, third, fifth, '', '', ninth]})
    } else if (ext === '11') {
      this.setState({selected: [first, third, fifth, '', seventh, ninth, eleventh]})
    } else if (ext === 'add 11') {
      this.setState({selected: [first, third, fifth, '', '', '', eleventh]})
    } else if (ext === 'all') {
      this.setState({selected: [first, third, fifth, sixth, seventh, ninth, eleventh]})
    } else {
      this.setState({selected: [first, third, fifth]})
    }
  }
  freezeChord() {
    const {selected, frzChords, quality, ext, strPitches} = this.state
    frzChords.push({selected, quality, ext, strPitches})
    this.setState({frzChords})
  }
  clearFrzChords() {
    this.setState({frzChords: []})
  }
  setStrPitch(event) {
    const idx = event.target.name, {strPitches} = this.state
    strPitches[idx] = event.target.value
    this.setState({strPitches})
  }
  setSharpsOrFlats(event) {
    const {strPitches} = this.state,
      sharpNotes = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B', 'C'],
      flatNotes = ['C', 'Db', 'D', 'Eb', 'E', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'B', 'C'],
      sharpsOrFlats = event.target.value
    const newStrPitches = sharpsOrFlats === 'sharps' ?
      strPitches.map(p => sharpNotes[flatNotes.indexOf(p)]) :
      strPitches.map(p => flatNotes[sharpNotes.indexOf(p)])
    this.setState({
      sharpsOrFlats,
      strPitches: newStrPitches,
      allNotes: sharpsOrFlats === 'sharps' ? 
        sharpNotes :
        flatNotes
    })
    this.getSelected()
  }
  setNumStrings(numStrings) {
    const {strPitches, allNotes} = this.state,
      oldNumStrings = strPitches.length
    if (
      !numStrings ||
      numStrings === '0' ||
      numStrings > 12
    ) return 
    if (oldNumStrings !== numStrings) {
      let newStrPitches = []
      for (let i = 0; i < numStrings; i++) {
        const oldPitch = strPitches[i],
          idx = allNotes.indexOf(newStrPitches[i - 1])
        oldPitch ?
          newStrPitches[i] = oldPitch :
          newStrPitches[i] = allNotes[idx + 7] || allNotes[idx - 5]
      }
      this.setState({strPitches: newStrPitches})
      this.getSelected()
    }
  }
  allStringsStep(direction) {
    const {strPitches, allNotes, frzChords} = this.state
    const newStrPitches = strPitches.map(p => {
      if (direction === 'sharp'){
        if (p === 'C')  return allNotes[1]
        return allNotes[allNotes.indexOf(p) + 1]
      } else {
        if (p === 'C')  return allNotes[11]
        return allNotes[allNotes.indexOf(p) - 1]
      }
    })
    this.setState({
      strPitches: newStrPitches,
      frzChords: frzChords.map(c => {
        return {...c, strPitches: newStrPitches}
      })
    })
  }
  componentDidMount() {
    this.getSelected()
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {allNotes, chordTonic, selected, quality, ext, strPitches} = this.state
    const prevStrPitches = prevState.strPitches,
      prevAllNotes = prevState.allNotes
    if (
      chordTonic !== selected[0] ||
      prevState.quality !== quality ||
      prevState.ext !== ext ||
      !strPitches.every((p, i) => p === prevStrPitches[i]) ||
      !allNotes.every((p, i) => p === prevAllNotes[i])
    )  this.getSelected()
  }
  render() {
    const {strPitches, chordTonic, frzChords, selected, quality, ext, allNotes, sharpsOrFlats} = this.state,
      frozen = frzChords.length > 0
    return (
      <div className="App">
        <h3>Alt Tuning Tool</h3>
        <Options 
          setSharpsOrFlats={this.setSharpsOrFlats.bind(this)}
          setNumStrings={this.setNumStrings.bind(this)}
          allStringsStep={this.allStringsStep.bind(this)}
          sharpsOrFlats={sharpsOrFlats} frozen={frozen}
          numStrings={strPitches.length}
        />
        <ChordSel 
          allNotes={allNotes} chordTonic={chordTonic} 
          handleChange={this.handleChange.bind(this)} 
          freezeChord={this.freezeChord.bind(this)}
          clearFrzChords={this.clearFrzChords.bind(this)}
          quality={quality} ext={ext} 
        />
        <div className="neck">
          <FretNumbers numOfFrets={[0,12]} />
          <div className={`all-strings has-${strPitches.length}-strs`}>
            {strPitches.map((p, i) => 
              <String name={i} pitch={p} allNotes={allNotes} setStrPitch={this.setStrPitch.bind(this)} selected={selected} key={i} />)
            }
          </div>
        </div>
        <div className="frozen-chords">
          {frzChords.map((c, i) => 
            <FrzChord allNotes={allNotes} chord={c} numOfFrets={[0,4]} key={i}/>)
          }
        </div>
        <div className='contact'>
          <span>
            contact: <a href="mailto:alttuningtool@gmail.com">alttuningtool@gmail.com</a><br/>
            © 2023 alttuningtool
          </span>
        </div>
      </div>
    )
  };
}

export default App;
